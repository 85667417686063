import { ordersKeys, routeKeys } from "api/keys";
import { orderKeys as newOrderKeys } from "./keys";
import { Partials } from "api/other/models";
import { PostDeadline } from "api/routes/models";
import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { queryFetch } from "apiConnectors/queryFetch";
import { CommentType } from "components/common";
import { ApiFetcher } from "hooks/createApiQuery";
import { dateFns, omit, queryString } from "utilities";
import { Assign } from "utility-types";
import { Invoice } from "../invoices/models";
import { Pagination, PaginationWithCounters } from "../types";
import {
  B2bWarehouseDeliveryDateBulkUpdate,
  CommentPost,
  ComplaintParent,
  ComplaintPicture,
  ComplaintPost,
  CreatePickingOrLoading,
  DailyStatistics,
  Delivery,
  GroupOrderPoint,
  ItemsWithSingleQuantities,
  JoinedOrder,
  Listings,
  ListOrder,
  LoadingStatus,
  Order,
  OrderAdditionalData,
  OrderBankTransactionsSearch,
  OrderCollectionDateConfirmation,
  OrderDeliveryInfo,
  OrderForRoute,
  OrderIsInWarehouseBulkUpdate,
  OrderItemsSearch,
  OrderMessagesSchema,
  OrderNearestRoutes,
  OrderPatch,
  OrderPoint,
  OrderPointsForRoute,
  OrderPost,
  OrderProductInstance,
  OrdersIndexPopularity,
  OrderStatusBulkUpdateDTO,
  OrderStatusTempBulkUpdateDTO,
  OrderTrackingInfo,
  OrderTransactions,
  PostDevelopmentOrdersSchema,
  PostReservationResponse,
  SimpleListOrder,
  SingleItemQuantities,
  SingleItemQuantitiesDetails,
  SingleItemQuantitiesForRoute,
  SplittedOrders,
  UpdateCustomerBulkUpdate,
  UpholsteryOrder,
} from "./models";

const api = tokenRefreshMiddleware(apiFetch);

export const postOrder = (data: OrderPost) =>
  api<any>({
    method: "POST",
    url: "/orders/items",
    data,
  });

export const getOrders = (search = "", abortToken?: string) =>
  api<PaginationWithCounters<ListOrder, "numOrders">>({
    method: "GET",
    url: "/orders/items" + search,
    abortToken,
  });

export const getOrdersItemsSearch = (search = "", abortToken?: string) =>
  api<Pagination<OrderItemsSearch>>({
    method: "GET",
    url: "/orders/items-search" + search,
    abortToken,
  });

export const getOrdersBankTransactions = (search = "", abortToken?: string) =>
  api<Pagination<OrderBankTransactionsSearch>>({
    method: "GET",
    url: "/orders/items-bank-transactions-search" + search,
    abortToken,
  });

export const getOrdersQuery = (search: string = "") => ({
  key: ordersKeys.orders(search),
  fetcher: () =>
    queryFetch<PaginationWithCounters<ListOrder, "numOrders">>({
      method: "GET",
      url: "/orders/items" + search,
    }),
});

export const getOrdersSimpleListQuery = (search: string = "") => ({
  key: ordersKeys.orders(search),
  fetcher: () =>
    queryFetch<PaginationWithCounters<SimpleListOrder, "numOrders">>({
      method: "GET",
      url: "/orders/simple-list-for-route" + search,
    }),
});

export const getOrdersLight = (search = "", abortToken?: string) =>
  api<PaginationWithCounters<ListOrder, "numOrders">>({
    method: "GET",
    url: "/orders/light" + search,
    abortToken,
  });

export const getOrder = (id: number | string, abortToken?: string) =>
  api<Order>({
    method: "GET",
    url: `/orders/items/` + id,
    abortToken,
  });

export const getOrderB2b = (id: number | string, abortToken?: string) =>
  api<Order>({
    method: "GET",
    url: `/orders/customer/items/` + id,
    abortToken,
  });

export const getOrderQuery = (id: number | string) => ({
  key: ordersKeys.order(String(id)),
  fetcher: () =>
    queryFetch<Order>({
      method: "GET",
      url: "/orders/items/" + id,
    }),
});

export const getListings = (orderId: string | number): ApiFetcher<Listings> => ({
  key: ordersKeys.compareOrder(String(orderId)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/listings/order-items-comparison/" + orderId,
    }),
});

export const getLoadingStatus = (orderId: string | number): ApiFetcher<LoadingStatus> => ({
  key: ordersKeys.loadingStatus(String(orderId)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/orders/items/${orderId}/loading-status`,
    }),
});

export const getOrderB2bQuery = (id: number | string): ApiFetcher<Order> => ({
  key: ordersKeys.order(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/customer/items/" + id,
    }),
});

export const getOrderForRoute = (id: number | string, abortToken?: string) =>
  api<OrderForRoute>({
    method: "GET",
    url: "/orders/details-for-route/" + id,
    abortToken,
  });

export const getOrderForRoute_query = (id: number | string): ApiFetcher<OrderForRoute> => ({
  key: ordersKeys.orderForRoute(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/details-for-route/" + id,
    }),
});

export const patchOrder = (id: string | number, data: Partial<OrderPost>) =>
  api<Order>({
    method: "PATCH",
    url: "/orders/items/" + id,
    data,
  });

export const bulkUpdateOrderCustomer = (data: UpdateCustomerBulkUpdate) =>
  queryFetch<UpdateCustomerBulkUpdate>({
    method: "PATCH",
    url: "/orders/items/bulk-update",
    data,
  });

export const patchOrder_query = (id: string | number, data: Partial<Order>) =>
  queryFetch<Order>({
    method: "PATCH",
    url: "/orders/items/" + id,
    data,
  });

export const bulkUpdateOrderStatus = (data: OrderStatusTempBulkUpdateDTO) =>
  queryFetch<OrderStatusTempBulkUpdateDTO>({
    method: "PATCH",
    url: "/orders/items/bulk-update",
    data,
  });

export const patchOrderQuery = (id: string | number, data: Partial<OrderPatch>) =>
  queryFetch<Order>({
    method: "PATCH",
    url: "/orders/items/" + id,
    data,
  });

export const bulkUpdateOrder = (data: OrderStatusBulkUpdateDTO) =>
  queryFetch<OrderStatusBulkUpdateDTO>({
    method: "PATCH",
    url: "/orders/items/bulk-update",
    data,
  });

export const confirmOrder = (data: {
  orderId: number;
  isConfirmed: boolean;
  point?: { lng: number; lat: number };
  countryCode?: string;
}) =>
  api<{ isValid: boolean }>({
    method: "POST",
    url: "/orders/confirm",
    data,
  });

export const bulkUpdateOrderIsInWarehouse = (data: OrderIsInWarehouseBulkUpdate) =>
  queryFetch<OrderIsInWarehouseBulkUpdate>({
    method: "PATCH",
    url: "/orders/items/bulk-update",
    data,
  });

export const postComment = (data: CommentPost) =>
  api<CommentType>({
    method: "POST",
    url: "/orders/comments",
    data,
  });

export const postComment_query = (data: CommentPost) =>
  queryFetch<CommentType>({
    method: "POST",
    url: "/orders/comments",
    data,
  });

export const getOrdersPoints = (search = "") =>
  api<{ results: OrderPoint[] }>({
    method: "GET",
    url: "/orders/points" + search,
  });

export const getPointsForRoute = (search = "") => ({
  key: routeKeys.routeCreatorPoints(search),
  fetcher: () =>
    queryFetch<OrderPointsForRoute>({
      method: "GET",
      url: "/orders/points-for-route" + search,
    }),
});

export const getDailyStatistics = (search: string) =>
  api<{ categories: DailyStatistics[] }>({
    method: "GET",
    url: "/orders/daily-stats" + search,
  });

export const getPointsForGroup = (search = "") =>
  api<{ results: GroupOrderPoint[] }>({
    method: "GET",
    url: "/orders/points-for-group" + search,
  });

export const patchOrderProduct = (
  id: string | number,
  data: Assign<Partial<OrderProductInstance>, { product?: number }>,
  abortToken?: string,
) =>
  api<any>({
    method: "PATCH",
    url: "/orders/products/" + id,
    data,
    abortToken,
  });

export const patchOrderProductPackage = (
  id: string | number,
  data: { isAddedToComplaint?: boolean; quantity?: number },
  abortToken?: string,
) =>
  api<OrderProductInstance["packages"][number]>({
    method: "PATCH",
    url: "/orders/item-packages/" + id,
    data,
    abortToken,
  });

export const postOrderProduct = (
  data: Assign<Partial<OrderProductInstance>, { product: number }>[],
) =>
  api<OrderProductInstance[]>({
    method: "POST",
    url: "/orders/products",
    data,
  });

export const deleteOrderProduct = (id: number) =>
  api<void>({
    method: "DELETE",
    url: "/orders/products/" + id,
  });

export const deleteOrderProductSet = (orderId: number, productSetCode: string) =>
  api<void>({
    method: "DELETE",
    url: `/orders/items/${orderId}/remove-set/${productSetCode}`,
  });

export const deleteComment = (id: number) =>
  api<void>({
    method: "DELETE",
    url: "/orders/comments/" + id,
  });

export const deleteComment_query = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/orders/comments/" + id,
  });

export const patchComment_query = (comment: Partial<CommentType> & { id: number }) =>
  queryFetch<CommentType>({
    method: "PATCH",
    url: "/orders/comments/" + comment.id,
    data: omit(comment, ["id"]),
  });
export const patchComment = (comment: Partial<CommentType> & { id: number }) =>
  api<CommentType>({
    method: "PATCH",
    url: "/orders/comments/" + comment.id,
    data: omit(comment, ["id"]),
  });
export const getUpholsteries = (search = "", abortToken?: string) =>
  api<PaginationWithCounters<UpholsteryOrder, "numProductionItems">>({
    method: "GET",
    url: "/orders/upholsteries" + search,
    abortToken,
  });

export const getUpholstery = (id: string | number, abortToken?: string) =>
  api<UpholsteryOrder>({
    method: "GET",
    url: "/orders/upholsteries/" + id,
    abortToken,
  });

export const postSplitOrder = (data: {
  orderId: number;
  itemsToNewOrder: number[];
  amounts: { newOrder: string; originOrder: string };
}) =>
  api<{ orders: SplittedOrders }>({
    method: "POST",
    url: "/orders/split",
    data,
  });

export const getPdf = (url: string) =>
  api<ArrayBuffer>({
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/pdf",
    },
    responseType: "arraybuffer",
  });

export const getCustomerOrders = (search = "", abortToken?: string) =>
  api<Pagination<ListOrder>>({
    method: "GET",
    url: "/orders/from-customer" + search,
    abortToken,
  });

export const getCustomerOrdersQuery = (search: string = "") => ({
  key: ordersKeys.orders(search),
  fetcher: () =>
    queryFetch<Pagination<ListOrder>>({
      method: "GET",
      url: "/orders/from-customer" + search,
    }),
});

export const getCustomerOrder = (id: number | string, abortToken?: string) =>
  api<Order>({
    method: "GET",
    url: "/orders/from-customer/" + id,
    abortToken,
  });

export const postCustomerOrder = (data: OrderPost) =>
  api<Order>({
    method: "POST",
    url: "/orders/from-customer",
    data,
  });

export const createPicking = (data: CreatePickingOrLoading) =>
  queryFetch<void>({
    method: "POST",
    url: "/orders/create-picking",
    data,
  });

export const createLoading = (data: CreatePickingOrLoading) =>
  queryFetch<void>({
    method: "POST",
    url: "/orders/create-loading",
    data,
  });

export const bulkUpdateCustomerOrderWarehouseDeliveryDate = (
  data: B2bWarehouseDeliveryDateBulkUpdate,
) =>
  queryFetch<B2bWarehouseDeliveryDateBulkUpdate>({
    method: "PATCH",
    url: "/orders/from-customer/bulk-update",
    data,
  });

export const mergeOrders = (
  data:
    | { ordersToMerge: number[]; auto: true }
    | {
        ordersToMerge: number[];
        auto: false;
        delivery: Partial<Delivery> | null;
        invoice: Partial<Invoice> | null;
        payment: Partial<Order["payment"]> | null;
        details: {
          isInvoiceRequired: boolean;
          salesAccountId: number | string;
          type: Order["type"];
        } | null;
      },
) =>
  api<{ order: number; signature: string }>({
    method: "POST",
    url: "/orders/merge",
    data,
  });

export const getOrderTrackingInfo = (data: { orderId: string; trackingToken: string }) =>
  apiFetch<OrderTrackingInfo>({
    method: "POST",
    url: "/orders/tracking-info",
    data,
  });

export const postOrderSms = (data: { orderId: Order["id"]; message: string | null }) =>
  api<{ message: string }>({
    method: "POST",
    url: "/orders/send-sms",
    data,
  });

export const postOrderSms_query = (data: { orderId: Order["id"]; message: string | null }) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/orders/send-sms",
    data,
  });

export const postOrderRouteSms_query = (data: { id: Order["id"]; deadline: PostDeadline | null }) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/orders/messages/send-default-route-sms-message/" + data.id,
    data,
  });

export const orderFileFactory = (() => {
  function formatSignature(signature: string) {
    return signature.replace(/\//g, "-");
  }
  function getDate() {
    return dateFns.format(new Date(), "dd-MM-yyyy-HH-mm");
  }
  return {
    orderPdf: (order: Pick<Order, "id" | "signature" | "type">) => ({
      url: `/orders/${order.type === "STANDARD" ? "items" : "complaints"}/${order.id}/pdf`,
      name: `${formatSignature(order.signature)}-${getDate()}`,
    }),
    orderXml: (order: Pick<Order, "id" | "signature">) => ({
      url: `/orders/items/${order.id}/xml`,
      name: `${formatSignature(order.signature)}-${getDate()}`,
    }),
    ordersXml: () => ({
      url: `/orders/items/bulk-xml`,
      name: `paragony-${getDate()}`,
    }),
    orderWdt: (order: Pick<Order, "id" | "signature" | "type">) => ({
      url: `/orders/wdt-pdf/${order.id}/WDT-${order.id}`,
      name: `WDT-${order.signature}-${getDate()}`,
    }),
    orderGeneralPdf: (search: string) => ({
      url: `/orders/general-pdf${search}`,
      name: `Zamówienia-${getDate()}`,
    }),
    lastNotInvoicedOrders: () => ({
      url: "/orders/recent-not-invoiced-orders-report",
      name: `Zamówienia-niefakturowane-${getDate()}`,
    }),
    deliveryCostsPdf: (search: string) => ({
      url: `/orders/document-with-delivery-amount${search}`,
      name: `Kwota-za-transport-${getDate()}`,
    }),
    deliveryCostsCsv: (search: string) => ({
      url: `/orders/csv-document-with-delivery-amount${search}`,
      name: `Kwota-za-transport-${getDate()}`,
    }),
    ordersPdf: (search: string) => ({
      url: `/orders/orders-pdf${search}`,
      name: `Zamówienia-${getDate()}`,
    }),
    orderSpecificationPdf: (order: Pick<Order, "id" | "signature">) => ({
      url: `/orders/items/${order.id}/specification-pdf`,
      name: `${formatSignature(order.signature)}-${getDate()}`,
    }),
    orderLabelPdf: (order: Pick<Order, "id" | "signature">) => ({
      url: "/utils/print?order=" + order.id,
      name: `${formatSignature(order.signature)}-${getDate()}`,
    }),
    ordersCsv: (search: string) => ({
      url: `/exports/fv-data-for-selected-orders-standard${search}`,
      name: `rozliczenia_podsumowanie_${getDate()}`,
    }),

    ordersCsvOrderList: (search: string) => ({
      url: `/exports/wayfair-data-for-selected-orders${search}`,
      name: `rozliczenia_podsumowanie_${getDate()}`,
    }),

    ordersDeliveredSalesReport: (search: string) => ({
      url: `/orders/delivered-sales-report-csv${search}`,
      name: `raport_sprzedaży_${getDate()}`,
    }),

    ordersSalesInTimeReport: (startDate: string, endDate: string) => ({
      url: `/orders/sales-in-time-amount${queryString.stringify({
        startDate,
        endDate,
        responseFormat: "csv",
      })}`,
      name: `raport_sprzedaży_${startDate}_${endDate}`,
    }),

    ordersCsvTransport: (search: string) => ({
      url: `/exports/fv-data-for-selected-orders-transport${search}`,
      name: `rozliczenia_podsumowanie_${getDate()}`,
    }),
    orderDetailPdf: (order: Pick<Order, "id" | "signature" | "type">) => ({
      url: `/orders/items/${order.id}/get-detail-pdf`,
      name: `${formatSignature(order.signature)}-${getDate()}`,
    }),
    deliveryPhotosZip: (order: Pick<Order, "id" | "signature" | "type">) => ({
      url: `/orders/zip-with-delivery-photo?orders=${order.id}`,
      name: `delivery-photos-${formatSignature(order.signature)}-${getDate()}`,
    }),
    getPaymentsSummary: (salesAccount: Partials["salesAccounts"][number], search: string) => ({
      url: `/orders/get-payments-summary-for-date/${salesAccount.id}${search}`,
      name: `${formatSignature(salesAccount.name)}-${getDate()}`,
    }),
    indexPopularityCsv: (search: string) => ({
      url: `orders/indexes-popularity-csv${search}`,
      name: `popularność_indeksów_${getDate()}`,
    }),
    productPopularityCsv: (search: string) => ({
      url: `orders/products-popularity-csv${search}`,
      name: `popularność_produktów_${getDate()}`,
    }),
    outdatedIndexesCsv: () => ({
      url: `orders/with-differing-indexes-csv`,
      name: `nieaktualne_indeksy_${getDate()}`,
    }),
    orderCsv: (order: Pick<Order, "id" | "signature">) => ({
      url: `/orders/${order.id}/generate-csv`,
      name: `zamowienie_${formatSignature(order.signature)}_${getDate()}`,
    }),
    orderExampleCustomerCsv: (kind: string) => ({
      url: `/files-to-download/items/${kind}`,
      name: `Przykładowy plik`,
    }),
  };
})();

export const getOrderCollectionDateConfirmInfo = (orderId: string) =>
  apiFetch<OrderCollectionDateConfirmation>({
    method: "GET",
    url: `/orders/confirm-sms/${orderId}`,
  });

export const confirmOrderCollectionDate = (orderId: string) =>
  apiFetch<{ message: string }>({
    method: "POST",
    url: `/orders/confirm-sms/${orderId}`,
  });

export const confirmOrderCollectionDateByMiloUser = (id: number) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: `/orders/milo-user-confirm-sms`,
    data: {
      id,
    },
  });

export const rejectOrderByMiloUser = (id: number) =>
  queryFetch<void>({
    method: "PATCH",
    url: `/orders/milo-user-reject-sms`,
    data: {
      id,
    },
  });

export const getOrderIndexPopularity = (search: string) =>
  api<OrdersIndexPopularity>({
    method: "GET",
    url: "/orders/indexes-popularity" + search,
  });

export const getOrderBySignature = (signature: string) =>
  apiFetch<Pagination<ListOrder>>({
    method: "GET",
    url: "/orders/by-signature?signature=" + signature,
  });

export const postReservation = (data: { order: number }) =>
  api<PostReservationResponse>({
    method: "POST",
    url: "/orders/create-reservation",
    data,
  });

export const postOrderMultipleInvoiceUpdate = (data: {
  hasItemsBeenInvoiced: boolean;
  hasTransportBeenInvoiced: boolean;
  orders: number[];
}) =>
  api<{ hasItemsBeenInvoiced: boolean; hasTransportBeenInvoiced: boolean }>({
    method: "POST",
    url: `/orders/items/multiple-update-transport-and-items-invoice`,
    data,
  });

export const postReceipt = (data: { order: number; issued: string; externalId: string }) =>
  api<Order["receipt"]>({
    method: "POST",
    url: "/receipts/items",
    data,
  });
export const postReceiptQuery = (data: { order: number; issued: string; externalId: string }) =>
  queryFetch<Order["receipt"]>({
    method: "POST",
    url: "/receipts/items",
    data,
  });

export const patchReceipt = (
  id: number | string,
  data: Partial<Omit<Order["receipt"], "order" | "id">>,
) =>
  api<Order["receipt"]>({
    method: "PATCH",
    url: "/receipts/items/" + id,
    data,
  });
export const patchReceiptQuery = (
  id: number | string,
  data: Partial<Omit<Order["receipt"], "order" | "id">>,
) =>
  queryFetch<Order["receipt"]>({
    method: "PATCH",
    url: "/receipts/items/" + id,
    data,
  });

export const patchMultipleReceipt = (data: { objects: number[]; issued: string }) =>
  api<Order["receipt"]>({
    method: "PATCH",
    url: "/receipts/items/bulk-update",
    data,
  });

export const postReceiptFile = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/receipts/upload-receipts",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.xlsm"',
    },
    onUploadProgress,
  });

export const postXmlFromDomando = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/orders/import-xml-from-domando",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.xlsm"',
    },
    onUploadProgress,
  });

export const postXmlFromMirjan = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "xmls/import/orders/mirjan",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.xlsm"',
    },
    onUploadProgress,
  });

export const postXmlFromBrw = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "xmls/import/orders/brw",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.xlsm"',
    },
    onUploadProgress,
  });

export const postJsonFromShopify = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/deliveries/upload-shopify-date-file",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.json"',
    },
    onUploadProgress,
  });

export const getOrderNearestRoutes = (id: string | number) =>
  api<OrderNearestRoutes>({
    method: "GET",
    url: "/orders/find-nearest-routes/" + id,
  });

export const getOrderAdditionalData = (uuid: string): ApiFetcher<OrderAdditionalData> => ({
  key: ordersKeys.additionalData(uuid),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/additional-data/" + uuid,
    }),
});

export const getJoinedOrderQuery = (uuid: string): ApiFetcher<JoinedOrder> => ({
  key: ordersKeys.joinedOrder(uuid),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/joined-orders/" + uuid,
    }),
});

export const getJoinedOrder = (uuid: string) =>
  api<JoinedOrder>({
    method: "GET",
    url: "/orders/joined-orders/" + uuid,
  });

export const postComplaint = (data: ComplaintPost) =>
  api<{
    created: string;
    description: string;
    direction: string;
    id: number;
    kind: string;
    parent: number;
    reason: string;
    signature: string;
    warrantyStatus: string;
  }>({
    method: "POST",
    url: "/orders/complaints",
    data,
  });

export const postComplaintB2b = (data: ComplaintPost) =>
  api<{
    created: string;
    description: string;
    direction: string;
    id: number;
    kind: string;
    parent: number;
    reason: string;
    signature: string;
    warrantyStatus: string;
  }>({
    method: "POST",
    url: "/orders/complaints-b2b",
    data,
  });

export const getComplaintParent = (uuid: string): ApiFetcher<ComplaintParent> => ({
  key: ordersKeys.complaintParent(uuid),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "orders/complaint-parent-order-details/" + uuid,
    }),
});

export const deleteComplaintPictures = (id: number) =>
  api<void>({
    method: "DELETE",
    url: "complaints/pictures/" + id,
  });

export const postComplaintPicture = (data: FormData) =>
  api<ComplaintPicture>({
    method: "POST",
    url: "complaints/pictures",
    data,
  });

export const ordersDeliveriesBulkUpdate = (data: { objects: number[]; shippingService?: number }) =>
  api<{ shippingService?: number }>({
    method: "PATCH",
    url: "/orders/items/bulk-update-deliveries",
    data,
  });

export const postDevelopmentOrders = (data: PostDevelopmentOrdersSchema) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/orders/create-development-orders",
    data,
  });

export const postDevelopmentDrawOrdersCreatedDate = () =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "orders/developer/draw-order-created-date",
  });

export const getOrderSmsMessages = (id: string): ApiFetcher<OrderMessagesSchema> => ({
  key: ordersKeys.orderMessages(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/messages/sms/" + id,
    }),
});

export const getOrderTransactions = (id: number): ApiFetcher<OrderTransactions> => ({
  key: ordersKeys.orderTransactions(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/orders/items/${id}/transactions`,
    }),
});

export const getOrderDeliveryInfo = (id: string | number): ApiFetcher<OrderDeliveryInfo> => ({
  key: ordersKeys.orderDeliveryInfo(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/orders/post-delivery-info/${id}`,
    }),
});

export const postCsvWithOrders = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/orders/import-csv-with-orders-from-customer",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.csv"',
    },
    onUploadProgress,
  });

export const postCsvWithOrdersEC = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
  search: string = "",
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/orders/import-csv-with-orders-ec" + search,
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.csv"',
    },
    onUploadProgress,
  });

export const postTrackingTokenToExternalService = (data: { orders: number[] }) =>
  queryFetch({
    method: "POST",
    url: "/orders/send-tracking-token-to-external-services",
    data,
  });

export const getItemsWithSingleQuantities = (
  search: string = "",
): ApiFetcher<Pagination<ItemsWithSingleQuantities>> => ({
  key: newOrderKeys.itemsWithSingleQuantities(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/items-with-single-quantities" + search,
    }),
});

export const getSingleItemQuantitiesForRoute = (
  search: string = "",
): ApiFetcher<Pagination<SingleItemQuantitiesForRoute>> => ({
  key: newOrderKeys.singleItemQuantitiesForRoute(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/single-item-quantities-for-route" + search,
    }),
});

export const getSingleItemQuantities = (
  search: string = "",
): ApiFetcher<Pagination<SingleItemQuantities>> => ({
  key: newOrderKeys.singleItemQuantities(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/single-item-quantities" + search,
    }),
});

export const getSingleItemQuantitiesDetails = (
  search: string = "",
): ApiFetcher<Pagination<SingleItemQuantitiesDetails>> => ({
  key: newOrderKeys.singleItemQuantitiesDetails(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/single-item-quantities-details" + search,
    }),
});
