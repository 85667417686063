import { cx, getAnyErrorKey, yup } from "utilities";
import { ToggleHookState, useSelector, useToastr } from "hooks";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Formik } from "formik";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { FormikErrorMessage } from "components/utils/errorMessage/FormikErrorMessage";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select";
import { ShipmentListItem } from "api/shipping/models";
import { assertIsDefined } from "utilities/assertIsDefined";
import { shippingActions } from "api/shipping/actions";
import { Partials } from "api/other/models";

interface Props {
  modal: ToggleHookState;
  selectedRows: ShipmentListItem[];
  resetSelection: () => void;
  closePanel: () => void;
}

export const AssignCourier = ({ closePanel, modal, resetSelection, selectedRows }: Props) => {
  const shippingServices = useSelector(state => state.partials.shippingShippingServices);
  const toastr = useToastr();

  const reset = () => {
    modal.close();
    resetSelection();
  };

  const initialValues: {
    shipments: string[];
    shippingService: Partials["shippingShippingServices"][number] | null;
  } = {
    shipments: selectedRows.map(e => e.id),
    shippingService: null,
  };

  const patchShippingMutation = shippingActions.useAssignCourierService();

  return (
    <Modal
      close={reset}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Przypisz kuriera
        </Typography>
      }
      width={480}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, helpers) => {
          const shippingService = shippingServices.find(
            service => service.id === values.shippingService?.id,
          );
          assertIsDefined(shippingService);
          patchShippingMutation(values, {
            onSuccess: () => {
              helpers.setSubmitting(false);
              closePanel();
              resetSelection();
              return;
            },
            onError: error => {
              helpers.setSubmitting(false);
              helpers.setErrors(error.response?.data);
              toastr.open({
                type: "warning",
                title: "Wymagane działanie",
                text: getAnyErrorKey(error),
              });
            },
          });
        }}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="d-flex flex-column px-3 mb-1">
              <Select
                label="Wybierz kuriera"
                items={shippingServices.map(shippingService => ({
                  value: shippingService.id,
                  text: shippingService.name,
                  type: MenuItemType.TEXT,
                }))}
                onChange={value => {
                  const shippingService = shippingServices.find(service => service.id === value);
                  assertIsDefined(shippingService);
                  setFieldValue("shippingService", shippingService);
                }}
                selected={values.shippingService?.id || null}
              />
            </div>
            <FormikErrorMessage name="detail" />
            <div className="d-flex align-items-center gap-3 p-3 text-uppercase">
              <Button onClick={reset} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                disabled={isSubmitting}
                type="submit"
                size="medium"
                variant="deepPurple"
                className="text-uppercase"
              >
                Przypisz{isSubmitting && "..."}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

const validationSchema = yup.object().shape({
  shippingService: yup
    .string()
    .nullable()
    .required("Wybierz kuriera"),
});
