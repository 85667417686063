import { OrderType } from "api/orders/models";
import { Seller } from "api/sellers/models";
import { ISODateTime, UUID } from "api/types";
import { User, UserWithInitials } from "api/users/models";
import { CountryCode, CURRENCY_TYPE } from "CONSTANTS";
import { Assign } from "utility-types";
import { ReminderType } from "../reminders/models";
import { AccountancyAccount } from "api/accountancy-accounts/models";
import { User as AuthUser } from "ducks/auth";
import { AttributesState } from "api/wms/models";

export const countryFullNames: Record<CountryCode | string, string> = {
  AT: "AUSTRIA",
  CZ: "CZECHY",
  DE: "NIEMCY",
  FR: "FRANCJA",
  UK: "WIELKA BRYTANIA",
  HU: "WĘGRY",
  LT: "LITWA",
  PL: "POLSKA",
  SK: "SŁOWACJA",
  UA: "UKRAINA",
  IT: "WŁOCHY",
  LV: "ŁOTWA",
  SI: "SŁOWENIA",
};

export const countryCode: CountryCode[] = ["DE", "PL", "CZ", "SK", "FR", "HU", "AT"];

export type TradingDocumentTab = "all" | "paid" | "not-paid";

export type MyImportInvoicesTab = "all" | "opened" | "closed";

export type InvoiceType = "SALES" | "PURCHASE" | "CORRECTION" | "ADVANCE" | "FINAL" | "PROFORMA";

export type TradingDocumentStatus = "NEW" | "CONFIRMED" | "WORK_IN_PROGRESS";

export type TradingDocumentType = "INVOICE" | "RECEIPT";

export type TradingDocumentPaymentStatus = "NOT_PAID" | "PAID" | "PARTIALLY_PAID";

export type ExpectedPaymentForm = "CASH" | "ONLINE" | "";

export type CorrectionReasonField = "POSITION" | "QUANTITY" | "AMOUNT" | "DISCOUNT" | "VAT";

export type CorrectionReasonAction = "REMOVED" | "NEW" | "INCREMENT" | "DECREMENT";

export type ViesStatus =
  | "ACTIVE"
  | "INACTIVE"
  | "UNREGISTERED"
  | "NO_DATA"
  | "INVALID"
  | "NO_TAX_ID";

export interface TradingDocument {
  amountSummary: {
    totalTax: number;
    totalWithTax: number;
    totalWithTaxInWords: string;
    totalWithoutTax: number;
  };
  amountSummaryCurrencyConvert: {
    exchangeRate: number | null;
    exchangeRateDate: string | null;
    totalTax: number | null;
    totalWithTax: number | null;
    totalWithoutTax: number | null;
  };
  amountSummaryPerVatRate: {
    totalWithTax: number;
    totalWithoutTax: number;
    totalTax: number;
    vatRate: number;
  }[];
  amountSummaryPerVatRateCurrencyConvert: {
    exchangeRate: number | null;
    exchangeRateDate: string | null;
    totalTax: number | null;
    totalWithTax: number | null;
    totalWithoutTax: number | null;
    vatRate: number | null;
  }[];
  assignedTo: Assign<
    Omit<UserWithInitials, "id">,
    {
      id: number;
      isSuperuser: boolean;
      isActive: boolean;
      lastLogin: ISODateTime | null;
      phone: string;
    }
  > | null;
  basePaymentDeadlineDelta: number;
  buyer: Seller | null;
  buyerBankAccountNumber: string;
  buyerBankName: string;
  buyerCity: string;
  buyerCompanyName: string;
  buyerEmail: string;
  buyerFirstName: string;
  buyerLastName: string;
  buyerPhone: string;
  buyerPostCode: string;
  buyerStreet: string;
  buyerTaxId: string;
  canBeFiscalized: boolean;
  corrections: {
    createdAt: string;
    id: UUID;
    invoiceDeliveryDate: string | null;
    paymentDeadline: string | null;
    reasons: {
      field: CorrectionReasonField;
      action: CorrectionReasonAction;
    }[];
    signature: string;
    status: TradingDocumentStatus;
  }[];
  correctionReason: string;
  correspondingDocuments: CorrespondingDocument[];
  correspondingReceipt: CorrespondingReceipt | null;
  counter: string;
  countryCode: CountryCode;
  createdAt: string;
  createdBy: number;
  currency: string;
  customer: TradingDocumentCustomer | null;
  deliveredAt: ISODateTime | null;
  description: string;
  expectedPaymentForm: ExpectedPaymentForm;
  finalTotalAmountWithTax: number;
  hasCorrectionRequired: boolean;
  id: UUID;
  invoiceDeliveryDate: ISODateTime | null;
  invoiceIssueDate: ISODateTime | null;
  invoicePaymentDate: ISODateTime | null;
  invoiceType: InvoiceType;
  isDelivered: boolean;
  isGrnConfirmed: boolean;
  isInVatSettlement: boolean;
  isManagedManually: boolean;
  isSentToRecipient: boolean;
  isSplitPayment: boolean;
  items: {
    id: UUID;
    order: {
      created: string;
      id: number | string;
      isDelivered: boolean;
      signature: string;
    } | null;
    tradingDocumentItems: TradingDocumentItem[];
  }[];
  kind: TradingDocumentKind;
  lastValidExchangeRate: string | null;
  lastValidExchangeRateDate: string | null;
  latestMessageStatus: TradingDocumentNotificationStatus;
  ledgerAccount: AccountancyAccount;
  masterDocument: {
    id: UUID;
    invoiceDeliveryDate: string | null;
    invoiceIssueDate: string | null;
    invoicePaymentDate: string | null;
    signature: string;
  } | null;
  notes: string;
  notificationsSent: TradingDocumentNotification[];
  orders: {
    id: number;
    isCanceled: boolean;
    isDelivered: boolean;
    isSettled: boolean;
    signature: string;
    type: OrderType;
  }[];
  payments: TradingDocumentPayment[];
  paymentDeadline: ISODateTime | null;
  paymentDeadlineDelta: number | null;
  paymentStatus: TradingDocumentPaymentStatus;
  pitTaxObligationDate: string | null;
  priceModifier: {
    amountModifiers?: {
      discount?: string;
      margin?: string;
    };
    priceList?: {
      id: number;
      name: string;
    };
  };
  printingStatus: ReceiptPrintingStatus;
  printingRequestedAt: ISODateTime | null;
  printingRequestedBy: Assign<
    Omit<UserWithInitials, "id">,
    {
      id: number;
      isSuperuser: boolean;
      isActive: boolean;
      lastLogin: ISODateTime | null;
      phone: string;
    }
  > | null;
  proforma: {
    id: UUID;
    signature: string;
  } | null;
  reasons: {
    field: CorrectionReasonField;
    action: CorrectionReasonAction;
  }[];
  remindersSent: ReminderType[];
  recipientBankAccountNumber: string;
  recipientBankName: string;
  recipientCity: string;
  recipientCompanyName: string;
  recipientEmail: string;
  recipientFirstName: string;
  recipientLastName: string;
  recipientPhone: string;
  recipientPostCode: string;
  recipientStreet: string;
  recipientTaxId: string;
  seller: Seller | null;
  sellerBankAccountNumber: string;
  sellerBankName: string;
  sellerCity: string;
  sellerCompanyName: string;
  sellerEmail: string;
  sellerFirstName: string;
  sellerLastName: string;
  sellerPhone: string;
  sellerPostCode: string;
  sellerStreet: string;
  sellerTaxId: string;
  sentToEmail: string;
  sentToRecipientBy: Pick<
    User,
    "avatar" | "firstName" | "id" | "isActive" | "isSuperuser" | "lastName" | "phone"
  >;
  sentToRecipientDate: string | null;
  signatureOfAnAuthorizedPerson: string;
  signature: string;
  status: TradingDocumentStatus;
  tradingDocumentPdf: TradingDocumentPdf | null;
  type: TradingDocumentType;
  vatTaxObligationDate: string | null;
  viesCheckedAt: string | null;
  viesValidStatus: ViesStatus;
  whiteListCheckedAt: string | null;
  whiteListValidStatus: ViesStatus;
  xmlOptimaDownloadAt: string;
  xmlOptimaDownloadBy: UserWithInitials | null;
}

export type TradingDocumentKind = "RETAIL" | "WHOLESALE" | "DROPSHIPPING" | "OWN_ENTITY";

export enum ReceiptPrintingStatus {
  FAILED = "FAILED",
  FINISHED = "FINISHED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
}

export enum ReceiptPrintingDisabledStatus {
  RECEIPT_0_AMOUNT = "RECEIPT_0_AMOUNT",
}

export interface CorrespondingReceipt {
  id: UUID;
  isPrintingAllowed: boolean;
  signature: string;
  printingDisabledStatus: ReceiptPrintingDisabledStatus | null;
  printingStatus: ReceiptPrintingStatus;
  printingRequestedAt: string | null;
}

export interface BulkSalesInvoiceConfirmPreview {
  invalid: Record<string, { message: string; objects: { id: UUID; signature: string }[] }>;
  valid: {
    message: string;
    objects: { id: UUID; signature: string }[];
  };
}

export interface CorrespondingDocument {
  id: UUID;
  invoiceDeliveryDate: ISODateTime | null;
  invoiceIssueDate: ISODateTime | null;
  invoiceType: InvoiceType;
  isDraft: boolean;
  signature: string | null;
  type: TradingDocumentType;
  status: TradingDocumentStatus;
}

export interface CurrentlyPrintedReceipt {
  id: UUID;
  orders: string[];
  printingRequestedAt: string | null;
  printingRequestedBy: {
    avatar: string | null;
    firstName: string;
    id: number;
    lastName: string;
  } | null;
  recipientFirstName: string;
  recipientLastName: string;
  signature: string;
}

export interface TradingDocumentCustomer {
  id: string | number;
  name: string;
  hasDropShipping: boolean;
  hasAmountToCollect: boolean;
  canAddNewIndexes: boolean;
  color: string;
  textColor: string;
  taxId: string | null;
}

export type TradingDocumentNotificationAction =
  | "STANDARD"
  | "CORRECTION"
  | "DUPLICATE"
  | "CORRECTION_DUPLICATE";

export type TradingDocumentNotificationStatus = "OPENED" | "SENT" | "DECLINED" | "CLICKED_LINK";

export interface TradingDocumentNotification {
  action: TradingDocumentNotificationAction;
  date: ISODateTime | null;
  recipient: string;
  sentBy: Pick<User, "avatar" | "firstName" | "id" | "lastName">;
  status: TradingDocumentNotificationStatus;
}

export interface ViesStatusPayload {
  viesCheckedAt: string;
  viesValidStatus: ViesStatus;
}

export interface WhiteListStatusPayload {
  whiteListCheckedAt: string;
  whiteListStatus: ViesStatus;
}

export interface UpdateStatusPayload {
  counter: number;
  isDraft: boolean;
  message: BulkSalesInvoiceConfirmPreview;
  signature: string;
  status: TradingDocumentStatus;
}

export type TradingDocumentPaymentKind = "ADVANCE" | "PAYMENT" | "REFUND" | "OTHER";

export type TradingDocumentPaymentMode = "AUTO" | "MANUAL";

export type TradingDocumentPaymentType = "CASH" | "ONLINE";

export type TradingDocumentPaymentProvider = "PAYPRO" | "P24" | "PAYU" | "BLUE_MEDIA";

export interface CreateTradingDocumentPaymentItem {
  id: UUID;
  totalAmount: number;
  name: string;
  vatRate: number;
  quantity: number;
  initialAmount: number;
}

export interface CreateTradingDocumentPayment {
  amount: string | null;
  bankTransactionDate: string | null;
  bankTransactionNumber: string;
  commission: number | null;
  createdAt: string;
  createdBy: AuthUser;
  currency: string;
  kind: "ADVANCE" | "PAYMENT" | "REFUND";
  paymentDate: string | null;
  paymentNumber: string;
  provider: string;
  tradingDocument: UUID;
  type: TradingDocumentPaymentType;
  items: CreateTradingDocumentPaymentItem[];
}

export interface LightTradingDocument {
  createdAt: ISODateTime;
  createdBy: number | null;
  id: UUID;
  invoiceType: InvoiceType;
  signature: string;
  status: TradingDocumentStatus;
  type: TradingDocumentType;
}

export interface TradingDocumentPayment {
  amount: number;
  bankTransactionDate: string | null;
  bankTransactionNumber: string;
  commission: number | null;
  createdAt: string;
  createdBy: Pick<
    User,
    "firstName" | "lastName" | "avatar" | "initialsBackgroundColor" | "initialsTextColor"
  >;
  currency: string;
  id: UUID;
  kind: TradingDocumentPaymentKind;
  mode: TradingDocumentPaymentMode;
  paymentDate: string | null;
  paymentNumber: string;
  provider: string;
  relatedTradingDocument: {
    id: UUID;
    signature: string;
  } | null;
  type: TradingDocumentPaymentType;
}

export interface TradingDocumentItem {
  addedMethod?: TradingDocumentItemAddedMethod;
  amountWithTax: number;
  amountWithTaxAfterDiscount: number;
  amountWithoutTax: number;
  codeCnPkwiu: string;
  currency: string;
  discount: number;
  discountAmount: number;
  displayPosition: number;
  ean: string;
  id: UUID;
  name: string;
  newAmountWithTax: number;
  newAmountWithTaxAfterDiscount: number;
  newAmountWithoutTax: number;
  newDiscount: number;
  newQuantity: number;
  newTotalAmountWithTaxAfterDiscount: number;
  newVatRate: number;
  note: string;
  oldAmountWithTax: number;
  oldAmountWithTaxAfterDiscount: number;
  oldAmountWithoutTax: number;
  oldDiscount: number;
  oldQuantity: number;
  oldTotalAmountWithTaxAfterDiscount: number;
  oldVatRate: number;
  quantity: number;
  sourceIndex: number;
  sourceItem: number;
  taxAmount: number;
  totalAmountWithTaxAfterDiscount: number;
  unit: string;
  vatRate: number;
  verboseAttributesValues: string;
}

export type TradingDocumentItemAddedMethod = "AUTOMATICALLY" | "MANUALLY";

export type PreviewTradingDocument = Assign<
  Omit<TradingDocument, "orders">,
  { orders: OrderForCreatingInvoice[]; sellerId: UUID }
>;

export type PreviewCorrectionDocument = Assign<
  Omit<TradingDocument, "orders">,
  { orders: OrderForCreatingInvoice[]; sellerId: UUID; masterDocumentSignature?: UUID }
>;

export interface CreateCorrectionDocumentPayload {
  tradingDocument: UUID;
  positions: {
    amountWithTax: number;
    discount: number;
    discountAmount: number;
    indexId: number;
    name: string;
    orderItemId: string;
    sourceId: string;
    quantity: number;
    vatRate: number;
  }[];
}

export interface OrderForCreatingInvoice {
  orderId: string;
  orderSignature: string;
  positions: OrderPositionForCreatingInvoice[];
}

export interface OrderPositionForCreatingInvoice {
  alreadyInvoiced: boolean;
  amountWithTax: number;
  amountWithoutTax: number;
  currency: string;
  discount: number;
  discountAmount: number;
  displayPosition: number;
  indexId: number;
  masterDocument: TradingDocument | null;
  name: string;
  newAmountWithTax: number;
  newAmountWithoutTax: number;
  newDiscount: number;
  newQuantity: number;
  newVatRate: number;
  oldAmountWithTax: number;
  oldAmountWithoutTax: number;
  oldDiscount: number;
  oldQuantity: number;
  oldVatRate: number;
  orderItemId: string;
  quantity: number;
  reasons: {
    field: CorrectionReasonField;
    action: CorrectionReasonAction;
  }[];
  sourceId: string;
  taxAmountBeforeRounding: number;
  vatRate: number;
}

export interface CreateTradingDocumentForSingleOrder {
  assignedTo: Pick<User, "avatar" | "firstName" | "id" | "lastName"> | null;
  items: number[];
  order: number;
  seller: string;
  type: TradingDocumentType | string;
  invoiceType?: InvoiceType | null;
  masterDocument: TradingDocument | null;
  recipientCity: string;
  recipientCompanyName: string;
  recipientEmail: string | null;
  recipientFirstName: string;
  recipientLastName: string;
  recipientPhone: string;
  recipientPostCode: string;
  recipientStreet: string;
  recipientTaxId: string;
  countryCode: string;
}

export interface ModifyTradingDocument {
  assignedTo: (Pick<User, "firstName" | "id" | "lastName"> & { avatar: string | null }) | null;
  countryCode: string;
  currency: CURRENCY_TYPE;
  items: number[];
  order: number;
  seller: Seller | string;
  correctionReason: string;
  expectedPaymentForm: string;
  type: TradingDocumentType | string;
  invoiceIssueDate: string | null;
  invoiceType?: InvoiceType | null;
  invoiceDeliveryDate: string | null;
  ledgerAccount: {
    id: string;
    name: string;
  } | null;
  masterDocument: TradingDocument | null;
  paymentDeadline: string | null;
  paymentStatus: TradingDocumentPaymentStatus;
  buyerCity: string;
  buyerCompanyName: string;
  buyerEmail: string;
  buyerFirstName: string;
  buyerLastName: string;
  buyerPhone: string;
  buyerPostCode: string;
  buyerStreet: string;
  buyerTaxId: string;
  recipientCity: string;
  recipientCompanyName: string;
  recipientEmail: string | null;
  recipientFirstName: string;
  recipientLastName: string;
  recipientPhone: string;
  recipientPostCode: string;
  recipientStreet: string;
  recipientTaxId: string;
  recipientBankAccountNumber: string;
  pitTaxObligationDate: string | null;
  vatTaxObligationDate: string | null;
  sellerCity?: string;
  sellerCompanyName?: string;
  sellerEmail?: string | null;
  sellerFirstName?: string;
  sellerLastName?: string;
  sellerPhone?: string;
  sellerPostCode?: string;
  sellerStreet?: string;
  sellerTaxId?: string;
  signature: string;
  description: string;
}

export interface CreateInvoiceForMultipleOrders {
  customerId: number | string;
}

export interface SendNotificationToRecipient {
  tradingDocumentId: UUID;
  recipientEmail?: string;
}

export interface SendEmailNotificationToMultiple {
  tradingDocumentsIds: UUID[];
}

export type InvoiceReviewerStatus = "AWAITING" | "ACCEPTED" | "CHANGES_REQUESTED";

export interface InvoiceReviewer {
  id: UUID;
  isMasterReviewer: boolean;
  status: InvoiceReviewerStatus;
  user: Omit<UserWithInitials, "token" | "type" | "customer" | "profile">;
}

export type TradingDocumentPdfProcessingStatus =
  | "ACCEPTED"
  | "REJECTED"
  | "NEW"
  | "IN_PROGRESS"
  | "DONE"
  | "FAILED";

export interface TradingDocumentPdf {
  createdAt: string;
  createdBy: number;
  id: UUID;
  pdf: string;
  failureReason: string;
  status: TradingDocumentPdfProcessingStatus;
  tradingDocument: UUID;
}

export type PurchaseInvoiceToReview = Assign<
  TradingDocument,
  { reviewers: InvoiceReviewer[]; tradingDocumentPdf: TradingDocumentPdf }
>;

export type ReviewStatus = "OPEN" | "CLOSED";

export interface MyPurchaseInvoiceToReview {
  createdAt: string;
  id: UUID;
  invoiceIssueDate: string | null;
  paymentDeadline: string | null;
  paymentStatus: TradingDocumentPaymentStatus;
  recipientCompanyName: string;
  recipientFirstName: string;
  recipientLastName: string;
  reviewers: InvoiceReviewer[];
  reviewStatus: ReviewStatus;
  sellerCompanyName: string;
  sellerFirstName: string;
  sellerLastName: string;
  signature: string;
  totalWithTax: string;
}

export type InvoiceAdmissionStatus = "NEW" | "CONFIRMED";

export interface InvoiceAdmission {
  amount: string;
  businessEntity: UUID | null;
  businessEntityName: string;
  createdAt: string;
  currency: string;
  currentAmount: string | null;
  id: UUID;
  name: string;
  reviewers: InvoiceReviewer[];
  status: InvoiceAdmissionStatus;
  tdCreatedAt: string;
  tdSignature: string;
  tradingDocument: UUID;
  whEntries: number[];
  whAggregateSearchDates: string[];
  whAggregateSearchManufacturer: {
    id: number | string;
    name: string;
  } | null;
  whAggregateSearchPackage: string;
  whAggregateSearchPageSize: string | number | null;
}

export interface ModifyInvoiceAdmission {
  whAggregateSearchDates: string[];
  whAggregateSearchManufacturer: {
    id: number | string;
    name: string;
  } | null;
  whAggregateSearchPackage: string;
  whAggregateSearchPageSize: string | number | null;
}

export interface LinkLinePackage {
  amount: number;
  createdAt: string;
  id: UUID;
  name: string;
  package: number;
  quantity: number;
}

export interface InvoiceAdmissionLinkLine {
  amount: string;
  confidence: 0 | 1 | 2;
  createdAt: string;
  id: UUID;
  invoiceAdmissionLink: UUID;
  isEdited: boolean;
  name: string;
  packages: LinkLinePackage[];
  quantity: number;
  tdItem: UUID;
}

export interface ModifyInvoiceAdmissionPackage {
  quantity: number;
  amount: number;
}

export interface WhEntryPackage {
  createdAt: string;
  id: UUID;
  internalId: string;
  leftQuantity: number;
  name: string;
  package: number;
  quantity: number;
  usedQuantity: number;
}

export type WhEntryAggregateStatus = "EMPTY" | "UNUSED" | "USED";

export interface WhEntryAggregate {
  id: UUID;
  invoiceAdmissionsLinks: {
    id: UUID;
    name: string;
  }[];
  items: WhEntryPackage[];
  manufacturers: {
    id: number;
    manufacturer: {
      id: number;
      name: string;
    };
  }[];
  signature: string;
  status: WhEntryAggregateStatus;
  whEntry: number;
  whEntryClosedAt: string | null;
  whEntryCreatedAt: string;
}

export interface AssignedWhEntry extends WhEntryAggregate {
  whEntryCreatedAt: string;
  invoiceAdmissionLink: UUID;
  items: WhEntryPackage[];
}

export interface AssignWhEntry {
  invoiceAdmissionLink: UUID;
  whEntryAggregate: UUID;
}

export interface AssigneWhEntryPreview extends AssignWhEntry {
  id: UUID;
}

export interface AssignPackageToWhEntryAggregate {
  whEntryAggregateItem: UUID;
  name: string;
  package: number;
  quantity: number;
  amount: string | number;
  item: UUID;
}

export interface ItemUsed {
  id: UUID;
  leftQuantity: number;
  quantity: number;
  usedQuantity: number;
}

export interface AssignPackageToLinkLinePreview {
  amount: string | number;
  createdAt: string;
  createdBy: number;
  id: UUID;
  item: UUID;
  itemUsed: ItemUsed;
  name: string;
  package: number;
  quantity: number;
  whEntryAggregateItem: UUID;
}

export interface AddCustomerInPurchaseInvoiceImport {
  seller: string;
  sellerBankAccountNumber: string;
  sellerBankName: string;
  sellerCity: string;
  sellerCompanyName: string;
  sellerEmail: string;
  sellerFirstName: string;
  sellerLastName: string;
  sellerPhone: string;
  sellerPostCode: string;
  sellerStreet: string;
  sellerTaxId: string;
}

export interface AddPurchaseInvoicePosition {
  name: string;
  vatRate: number;
  tradingDocumentOrder: UUID;
  unit: string;
  displayPosition: number;
  amountWithoutTax: number;
  quantity: number;
  discount: number;
  verboseAttributesValues?: string;
  note?: string;
  codeCnPkwiu?: string;
}

export interface WarehouseTotalValueSummary {
  whValueSummary: string | number | null;
}

export interface FinancialStateWarehouse {
  city: string;
  externalId: string;
  id: number;
  isDefault: boolean;
  name: string;
  point: string | null;
  postCode: string;
  street: string;
}

export interface WarehousePackagesInOrOutSummary {
  amount: string;
  id: UUID;
  inAt: string | null;
  inCount: number | null;
  internalId: string;
  item: {} | null;
  lastWhEntryIn: {
    id: number;
    signature: string;
  } | null;
  lastWhEntryOut: {
    id: number;
    signature: string;
  } | null;
  name: string;
  order: {} | null;
  outAt: string | null;
  outCount: number | null;
  package: number;
  warehouse: FinancialStateWarehouse | null;
}

export interface SummaryInWhEntry {
  amount: number;
  lastWhEntryInCreated: string | null;
  lastWhEntryInSignature: string | null;
  quantity: number;
  whEntry: number | null;
}

export interface SummaryPerInPackage {
  id: number;
  internalId: string;
  name: string;
  whEntries: SummaryInWhEntry[];
}

export interface SummaryOutWhEntry {
  amount: number;
  lastWhEntryOutCreated: string | null;
  lastWhEntryOutSignature: string | null;
  quantity: number;
  whEntry: number | null;
}

export interface SummaryPerOutPackage {
  id: number;
  internalId: string;
  name: string;
  whEntries: SummaryOutWhEntry[];
}

export interface FinancesAmountSummaryCurrency {
  amountWithoutTaxPln: number;
  amountWithoutTaxValue: number;
  taxPln: number;
  taxValue: number;
}

export interface FinancesAmountSummary {
  count: number;
  currencies: Record<string, FinancesAmountSummaryCurrency>;
  totalAmountWithoutTax: number;
  totalTax: number;
}

export interface PatchItemSummaryAmountPayload {
  amountWithoutTax?: number | string;
  discount?: number;
  amountWithTax?: number | string;
}

export interface PatchTradingDocumentItemsVatRatePayload {
  tradingDocumentItemsIds: UUID[];
  vatRate: number;
}

export type PatchTradingDocumentSummary = Pick<TradingDocument, "amountSummary" | "id">;

export enum TaxProcedure {
  NA = "NA",
  OSS = "OSS",
  ISG = "ISG",
  DOMESTIC = "DOMESTIC",
  EXPORT = "EXPORT",
}

export type CreateCorrectionModalState = {
  expectedPaymentForm: string;
  preview: PreviewCorrectionDocument;
  tradingDocumentId: UUID;
};

export interface CreateDraftDocumentPreview {
  id: UUID;
}

export interface CreateDraftDocumentLocationState {
  invoice: {
    id: UUID;
  };
}

export interface CreateDraftDocumentPositionPayload {
  tradingDocumentId: UUID;
  positions: {
    amountWithTax: number;
    discount: number;
    name: string;
    quantity: number;
    orderId: number | null;
    vatRate: number;
    indexId?: number | string;
  }[];
}

export type DraftDocumentPosition = Assign<
  TradingDocumentItem,
  {
    order: {
      created: string;
      id: number | string;
      signature: string;
    } | null;
  }
>;

export enum TradingDocumentSummaryType {
  TOTAL = "TOTAL",
  VAT_RATE = "VAT_RATE",
}

export interface NormalizedTradingDocumentSummary {
  id: UUID;
  totalWithoutTax: number;
  totalWithTax: number;
  totalTax: number;
  type: TradingDocumentSummaryType;
  vatRate?: number;
}

export interface NormalizedTradingDocumentExchangeRateSummary {
  id: UUID;
  exchangeRate: number | null;
  exchangeRateDate: string | null;
  totalWithoutTax: number | null;
  totalWithTax: number | null;
  totalTax: number | null;
  type: TradingDocumentSummaryType;
  vatRate?: number | null;
}

export interface ModifyDraftDocumentPosition {
  amountWithTax: number;
  discount: number;
  name: string;
  quantity: number;
  orderId: number | null;
  vatRate: number;
}

export interface AddProductToDraftInvoicePayload {
  tradingDocumentId: UUID;
  indexId: number | null;
  amountWithTax: number;
  discount: number;
  name: string;
  quantity: number;
  orderId: number | null;
  vatRate: number;
  productElements: {
    cuid: string;
    id: number;
    amount: string;
    name: string;
    attributesState: AttributesState;
    quantity: number;
    productSetCode: string;
    currency: CURRENCY_TYPE;
    product: number;
    index: number | null;
    note: string;
  }[];
}

export interface CreatePricing {
  modifiers?: {
    discount?: number;
    margin?: number;
  };
  priceListId?: number;
  tradingDocumentId: UUID;
}

export interface SetCurrencyPayload {
  currency: CURRENCY_TYPE;
  tradingDocumentId: UUID;
}

export enum FiscalizeReceiptReason {
  ALREADY_CONFIRMED = "ALREADY_CONFIRMED",
  MISSING_COUNTRY_CODE = "MISSING_COUNTRY_CODE",
  MISSING_POSITIONS = "MISSING_POSITIONS",
  POSITION_WITH_EMPTY_AMOUNT = "POSITION_WITH_EMPTY_AMOUNT",
  QUANTITY_LESS_THAN_0 = "QUANTITY_LESS_THAN_0",
  SELLER_PRINTER_NOT_CONFIGURED = "SELLER_PRINTER_NOT_CONFIGURED",
  VALID = "VALID",
}

interface MessageDetails {
  message: string;
  objects: {
    id: string;
    signature: string;
  }[];
}

export interface FiscalizeReceiptPayload {
  message: {
    invalid: Record<FiscalizeReceiptReason, MessageDetails>;
    valid: MessageDetails;
  };
}
